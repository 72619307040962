import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { FaTimes } from "react-icons/fa";
import toast from "react-hot-toast";
import axios from "axios";

const DeleteAccountModal = ({
  deleteAccountModal,
  setDeleteAccountModal,
  activeAccount,
  onDelete,
}) => {
  const deleteAccount = (e) => {
    axios("/accounts", {
      method: "DELETE",
      data: {
        _id: activeAccount._id,
      },
    }).then(() => {
      toast.success("Account deleted");
      setDeleteAccountModal(false);
      onDelete();
    });
    e.preventDefault();
  };

  return (
    <Transition.Root show={Boolean(deleteAccountModal)} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setDeleteAccountModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full transform overflow-hidden rounded-md bg-white p-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-sm sm:p-6">
                <button
                  onClick={() => setDeleteAccountModal(false)}
                  className="absolute top-[23px] right-5 opacity-25"
                >
                  <FaTimes />
                </button>
                <div className="mb-5 w-full">
                  <h3 className="pr-3">
                    Delete account {activeAccount.email}?
                  </h3>
                  <p className="mt-1.5 text-xs">
                    This will permanently delete your account.
                  </p>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    onClick={() => deleteAccount()}
                    type="button"
                    className="flex w-full items-center justify-center gap-1.5 rounded-md bg-red-500 px-4 py-3 font-medium leading-none text-button-text transition-all hover:bg-red-600 active:bg-red-700 lg:text-sm"
                  >
                    Yes, delete it!
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DeleteAccountModal;
