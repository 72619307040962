import clsx from "clsx";
import { useState } from "react";
import { redirectToPortal, updatePlan } from "../../utils/checkout";
import { useDataContext } from "../../context/UserContext";
import UpdatePlanModal from "../components/UpdatePlanModal";
import { FaCheck, FaDollarSign, FaTimes } from "react-icons/fa";
import ConfirmCancelModal from "../components/ConfirmCancelModal";
import { Disclosure, RadioGroup, Transition } from "@headlessui/react";
import { customPlans } from "../../data/config";
import {displayChurnkeyWindow, getChurnkeyHash} from "../../utils/settings";

function DynamicPlanDetails(plan) {
  return (
    <div className="grid w-full md:justify-end">
      <div className="flex flex-col justify-start gap-1.5 text-sm text-gray-800">
        <span className="flex items-center gap-1.5">
          <FaDollarSign className="text-sm text-highlight" />
          {plan.priceMonthly}/mo
        </span>
        <span className="flex items-center gap-1.5">
          <FaCheck className="text-sm text-highlight" />
          {plan.maxAccounts} Freebie Account(s)
        </span>
        <span className="flex items-center gap-1.5">
          <FaCheck className="text-sm text-highlight" />
          {plan.dealAccounts} Deal Account(s)
        </span>
        <span className="flex items-center gap-1.5">
          {plan.hasWarehouse ? (
            <FaCheck className="text-sm text-highlight" />
          ) : (
            <FaTimes className="text-sm text-highlight" />
          )}{" "}
          Warehouse Access
        </span>
      </div>
    </div>
  );
}

const Subscriptions = ({ plans, selectedPlan, setSelectedPlan }) => {
  const { user } = useDataContext();
  console.log(user)

  const [isUpdating, setIsUpdating] = useState(false);
  const [cancelOpen, setGonnaCancel] = useState(false);

  console.log(user.subscription);
  let usersPlan = plans.find((plan) =>
    plan.allPriceIds.includes(user.subscription.price_id)
  );
  if (!usersPlan)
    usersPlan = customPlans.find((plan) =>
      plan.allPriceIds.includes(user.subscription.price_id)
    );

  const isDowngrading = user.downgrading ? user.downgrading.newPrice : null;

  return (
    <div className="overflow-hidden shadow-md rounded-t-md">
      <div className="h-1 bg-gradient"></div>
      <section className="p-5 bg-white">
        <div className="mb-2">
          <div className="text-lg font-medium">Your plan</div>
          <p className="text-sm">
            View your current plan or update your plan to get access to more
            features.
          </p>
        </div>
        <form action="#" method="POST">
          <div>
            <div className="mb-6 space-y-6 bg-white">
              <RadioGroup onChange={setSelectedPlan}>
                <RadioGroup.Label className="sr-only">
                  {" "}
                  Pricing plans{" "}
                </RadioGroup.Label>
                <div className="relative -space-y-px bg-white rounded-md">
                  {plans.map((plan, planIdx) =>
                    plan.name === usersPlan.name ? (
                      <RadioGroup.Option
                        disabled={true}
                        key={plan.name}
                        value={plan}
                        className={({ checked }) =>
                          clsx(
                            planIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                            planIdx === plans.length - 1
                              ? "rounded-bl-md rounded-br-md"
                              : "",
                            checked
                              ? "z-10 border-orange-200 bg-orange-50"
                              : "border-gray-200",
                            "relative flex cursor-pointer flex-col border p-4 focus:outline-none md:grid md:grid-cols-3 md:pl-4 md:pr-6"
                          )
                        }
                      >
                        {() => (
                          <>
                            <span className="flex text-sm">
                              <span
                                className="flex items-center justify-center w-4 h-4 transition-all bg-white border border-transparent border-gray-300 rounded-full bg-grey"
                                aria-hidden="true"
                              >
                                <span className="h-1.5 w-1.5 rounded-full bg-white" />
                              </span>
                              <RadioGroup.Label
                                as="span"
                                className="ml-3 font-medium text-gray-900"
                              >
                                {plan.name}
                              </RadioGroup.Label>
                              <RadioGroup.Label
                                as="span"
                                className="ml-3 font-medium text-gray-500"
                              >
                                <span className="hidden sm:inline">
                                  Current Plan{" "}
                                  {user.subscription.deleted
                                    ? `Set to cancel on ${
                                        user.subscriptionStatus.split(" ")[1]
                                      }`
                                    : `Renews on ${
                                        user.subscriptionStatus.split(" ")[1]
                                      }`}
                                </span>
                                <span className="sm:hidden">
                                  {user.subscription.deleted
                                    ? "Cancels on"
                                    : "Renews"}{" "}
                                  {user.subscriptionStatus.split(" ")[1]}
                                </span>
                              </RadioGroup.Label>
                            </span>
                            <RadioGroup.Description
                              as="span"
                              className="pl-1 ml-6 text-sm md:ml-0 md:pl-0 md:text-center"
                            >
                              <span>${user.subscription.price} / mo</span>{" "}
                            </RadioGroup.Description>
                            <RadioGroup.Description
                              as="span"
                              className="pl-1 ml-6 text-sm md:ml-0 md:pl-0 md:text-right"
                            >
                              <Disclosure>
                                <Disclosure.Button
                                  type="button"
                                  className="text-sm font-medium text-gray-500 hover:text-gray-700"
                                >
                                  Show more
                                </Disclosure.Button>
                                <Transition
                                  enter="transition duration-100 ease-out"
                                  enterFrom="transform scale-95 opacity-0"
                                  enterTo="transform scale-100 opacity-100"
                                  leave="transition duration-75 ease-out"
                                  leaveFrom="transform scale-100 opacity-100"
                                  leaveTo="transform scale-95 opacity-0"
                                >
                                  <Disclosure.Panel className="px-0 pt-4 pb-2 text-sm text-gray-500">
                                    {DynamicPlanDetails(plan)}
                                  </Disclosure.Panel>
                                </Transition>
                              </Disclosure>
                            </RadioGroup.Description>
                          </>
                        )}
                      </RadioGroup.Option>
                    ) : (
                      <RadioGroup.Option
                        key={plan.name}
                        value={plan}
                        className={({ checked }) =>
                          clsx(
                            planIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                            planIdx === plans.length - 1
                              ? "rounded-bl-md rounded-br-md"
                              : "",
                            checked
                              ? "z-10 border-orange-200 bg-orange-50"
                              : "border-gray-200",
                            "relative flex cursor-pointer flex-col border p-4 focus:outline-none md:grid md:grid-cols-3 md:pl-4 md:pr-6"
                          )
                        }
                      >
                        {({ checked }) => (
                          <>
                            <span className="flex text-sm">
                              <span
                                className={clsx(
                                  checked
                                    ? "border-transparent bg-highlight"
                                    : "border-gray-300 bg-white",
                                  "flex h-4 w-4 items-center justify-center rounded-full border transition-all"
                                )}
                                aria-hidden="true"
                              >
                                <span className="h-1.5 w-1.5 rounded-full bg-white" />
                              </span>
                              <RadioGroup.Label
                                as="span"
                                className="ml-3 font-medium text-gray-900"
                              >
                                {plan.name}
                              </RadioGroup.Label>
                              {isDowngrading &&
                              isDowngrading === plan.priceId ? (
                                <span className="ml-3 font-medium text-red-500">
                                  Set to downgrade on{" "}
                                  {user.subscriptionStatus.split(" ")[1]}
                                </span>
                              ) : null}
                            </span>
                            <RadioGroup.Description
                              as="span"
                              className="pl-1 ml-6 text-sm md:ml-0 md:pl-0 md:text-center"
                            >
                              <span>${plan.priceMonthly} / mo</span>{" "}
                            </RadioGroup.Description>
                            <RadioGroup.Description
                              as="span"
                              className="pl-1 ml-6 text-sm md:ml-0 md:pl-0 md:text-right"
                            >
                              <Disclosure>
                                <Disclosure.Button
                                  type="button"
                                  className="text-sm font-medium text-gray-500 hover:text-gray-700"
                                >
                                  Show more
                                </Disclosure.Button>
                                <Transition
                                  enter="transition duration-100 ease-out"
                                  enterFrom="transform scale-95 opacity-0"
                                  enterTo="transform scale-100 opacity-100"
                                  leave="transition duration-75 ease-out"
                                  leaveFrom="transform scale-100 opacity-100"
                                  leaveTo="transform scale-95 opacity-0"
                                >
                                  <Disclosure.Panel className="px-0 pt-4 pb-2 text-sm text-gray-500">
                                    {DynamicPlanDetails(plan)}
                                  </Disclosure.Panel>
                                </Transition>
                              </Disclosure>
                            </RadioGroup.Description>
                          </>
                        )}
                      </RadioGroup.Option>
                    )
                  )}
                </div>
              </RadioGroup>
            </div>
            <div className="flex justify-between gap-3">
              <div className="flex gap-2.5">
                <button
                  type="button"
                  className={clsx(
                    "flex items-center gap-1.5 rounded-md bg-gray-100 px-4 py-3 text-sm font-medium leading-none tracking-wide transition-all hover:bg-gray-200 active:bg-gray-300 lg:inline-flex",
                    selectedPlan == null || selectedPlan.name == usersPlan.name
                      ? "pointer-events-none flex cursor-not-allowed select-none items-center gap-1.5 rounded-md bg-gray-100 px-4 py-3 text-sm font-medium leading-none tracking-wide opacity-50 transition-all hover:bg-gray-200 active:bg-gray-300 lg:inline-flex"
                      : "button-gradient cursor-pointer items-center gap-1.5 rounded-md px-4 py-3 text-sm font-medium leading-none tracking-wide text-button-text opacity-100 transition-all lg:inline-flex"
                  )}
                  onClick={
                    () => setIsUpdating(true)
                    // previewPlanChange(selectedPlan.priceId, (res) => {
                    //   if (res?.data?.isUpgrading) {
                    //     const dollars = (res?.data?.newPrice / 100).toLocaleString("en-US", {style:"currency", currency:"USD"});
                    //     const confirmPlanChange = window.confirm(`Cost to upgrade plan to ${selectedPlan.name}: ${dollars}.\nWould you like to confirm?`);
                    //     if (confirmPlanChange) {
                    //       updatePlan(selectedPlan.priceId, (res) => {
                    //         navigate("/dashboard");
                    //       })
                    //     } else {
                    //       navigate("/dashboard");
                    //       toast.error("Plan change cancelled.");
                    //       updateUser();
                    //     }
                    //   } else {
                    //     const confirmPlanChange = window.confirm(`Your plan will be downgraded to ${selectedPlan.name} on the next billing cycle.\nWould you like to confirm?`);
                    //     if (confirmPlanChange) {
                    //       updatePlan(selectedPlan.priceId, (res) => {
                    //         navigate("/dashboard");
                    //       })
                    //     } else {
                    //       toast.error("Plan change cancelled.");
                    //       updateUser();
                    //     }
                    //   }
                    // })
                  }
                >
                  Update plan
                </button>
                <button
                  type="button"
                  className="flex items-center gap-1.5 rounded-md bg-gray-100 px-4 py-3 text-sm font-medium leading-none tracking-wide transition-all hover:bg-gray-200 active:bg-gray-300 lg:inline-flex"
                  onClick={redirectToPortal}
                >
                  Manage Billing
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.6396 7.02527H12.0181V5.02527H19.0181V12.0253H17.0181V8.47528L12.1042 13.3892L10.6899 11.975L15.6396 7.02527Z"
                      fill="currentColor"
                    />
                    <path
                      d="M10.9819 6.97473H4.98193V18.9747H16.9819V12.9747H14.9819V16.9747H6.98193V8.97473H10.9819V6.97473Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
                <UpdatePlanModal
                  newPlan={selectedPlan || false}
                  currentPlan={usersPlan}
                  isUpdating={isUpdating}
                  setIsUpdating={setIsUpdating}
                />
              </div>
              {user.subscription.deleted ? (
                <button
                  type="button"
                  className="button-gradient items-center gap-1.5 rounded-md px-4 py-3 text-sm font-medium leading-none tracking-wide text-button-text transition-all lg:inline-flex"
                  onClick={() => {
                    updatePlan(usersPlan.priceId, (res) => {});
                  }}
                >
                  Uncancel Subscription
                </button>
              ) : isDowngrading ? (
                <button
                  type="button"
                  className="flex items-center gap-1.5 rounded-md bg-gray-100 px-4 py-3 text-sm font-medium leading-none tracking-wide transition-all hover:bg-gray-200 active:bg-gray-300 lg:inline-flex"
                  onClick={() => updatePlan(usersPlan.priceId, (res) => {})}
                >
                  Cancel Downgrade
                </button>
              ) : (
                <button
                  type="button"
                  className="flex items-center gap-1.5 rounded-md bg-gray-100 px-4 py-3 text-sm font-medium leading-none tracking-wide transition-all hover:bg-gray-200 active:bg-gray-300 lg:inline-flex"
                  onClick={() => {
                    displayChurnkeyWindow(user);
                  }}
                >
                  Cancel Subscription
                </button>
              )}
              <ConfirmCancelModal
                cancelOpen={cancelOpen}
                setGonnaCancel={setGonnaCancel}
              />
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default Subscriptions;
