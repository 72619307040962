import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate, useLocation } from "react-router-dom";

export default function Modal(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const [modalContent, setModalContent] = useState({});
  const [opened, setOpened] = useState(false);

  // useEffect(() => {
  //   TODO: Fetch modal content from API

  //   setModalContent({
  //     title: "Welcome to the Modal",
  //     body: "This is the body of the modal.",
  //     buttonText: "Click me!",
  //     closeButtonText: "Close",
  //     buttonLink: "/modal-land",
  //     image: "https://source.unsplash.com/random/800x600",
  //     id: "12",
  //   });
  // }, []);

  // useEffect(() => {
  //   if (localStorage.getItem("dialog") === modalContent.id) {
  //     setOpened(false);
  //   } else {
  //     setOpened(true);
  //   }
  // }, [modalContent]);

  const handleClose = () => {
    localStorage.setItem("dialog", modalContent.id);
    setOpened(false);
  };

  if (location.pathname === "/") {
    return (
      <Transition.Root show={opened} as={Fragment}>
        <Dialog as="div" className="relative z-[9999]" onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="flex flex-col justify-center">
                    <div className="text-center">
                      {modalContent?.title ? (
                        <Dialog.Title
                          as="h3"
                          className="mt-5 mb-2.5 text-xl font-semibold leading-none text-gray-900"
                        >
                          {modalContent?.title}
                        </Dialog.Title>
                      ) : null}

                      {modalContent?.body ? (
                        <div
                          className="mt-2 mb-2.5 text-gray-900"
                          dangerouslySetInnerHTML={{
                            __html: modalContent?.body,
                          }}
                        />
                      ) : null}

                      {modalContent?.image ? (
                        <img
                          src={modalContent?.image}
                          alt=""
                          className="mt-5 h-auto w-full rounded-md shadow-md"
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    {modalContent?.buttonText ? (
                      <button
                        type="button"
                        className="button-gradient flex w-full justify-center"
                        onClick={() => {
                          handleClose();
                          navigate(modalContent?.buttonLink);
                        }}
                      >
                        {modalContent?.buttonText}
                      </button>
                    ) : null}
                    <button
                      type="button"
                      className="mt-2.5 flex w-full justify-center rounded-lg bg-gray-100 py-3.5 text-sm font-medium !text-black transition hover:bg-gray-200 active:bg-gray-300"
                      onClick={handleClose}
                    >
                      {modalContent?.closeButtonText || "Close this"}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  } else {
    return null;
  }
}
