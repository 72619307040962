const CategoryList = ({
  categories,
  selectedCategory,
  setSelectedCategory,
}) => {
  return (
    <ul className="space-y-2">
      {categories.map((category, index) => (
        <li key={index} className="">
          <p
            className={`ml-0 cursor-pointer text-gray-700 transition-all hover:ml-1 ${
              category === selectedCategory ? "text-gradient" : ""
            }`}
            onClick={() => {
              setSelectedCategory(
                selectedCategory === category ? "" : category
              );
            }}
          >
            {category}
          </p>
        </li>
      ))}
    </ul>
  );
};

export default CategoryList;
