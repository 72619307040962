import axios from "axios";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const SignIn = ({
  email,
  setEmail,
  password,
  setPassword,
  setCurrentStep,
  goBackward,
  code,
  setCode,
  codeValid,
  setCodeValid,
}) => {
  const sendToServer = async () => {
    if (!codeValid) {
      toast.error("Invalid 2FA Code");
      setCode("");
      return;
    }
    axios("/accounts", {
      method: "PUT",
      data: {
        email: email,
        password: password,
        twoFactorKey: code,
        isDealAccount: false,
        controls: {
          discount: 100,
          minPrice: 0,
          maxPrice: 1,
        },
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setCurrentStep(6);
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 409) {
            toast.error("Account already exists!");
          } else {
            toast.error("Something went wrong");
          }
        }
      });
  };

  useEffect(() => {
    if (code.length > 0)
      if (/^[A-Z2-7]{32,64}$/.test(code)) {
        setCodeValid(true);
      } else {
        setCodeValid(false);
        toast.error("Invalid 2FA Code");
      }
  }, [code]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        sendToServer();
      }}
    >
      <p className="mt-0 pt-0">
        Almost done! Freebie Flow now requires your Amazon account email and
        password so that it can check out items on your behalf. All account
        information is encrypted and securely stored in the cloud.
      </p>
      <div className="flex flex-col gap-2.5 sm:flex-row">
        <label className="w-full">
          <span className="text-sm font-medium">Amazon Email</span>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm !ring-0 transition focus:border-highlight focus:outline-none sm:text-sm"
            placeholder="Amazon Email..."
            required
          />
        </label>
        <label className="mb-2.5 w-full">
          <span className="text-sm font-medium">Amazon Password</span>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className=" block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm !ring-0 transition focus:border-highlight focus:outline-none sm:text-sm"
            placeholder="Amazon Password..."
            required
          />
        </label>
      </div>
      <label className="w-full">
        <span className="text-sm font-medium">2FA Code</span>
        <input
          className=" block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm !ring-0 transition focus:border-highlight focus:outline-none sm:text-sm"
          placeholder="Paste your 2FA Secret from earlier..."
          type="text"
          value={code}
          onChange={(e) => {
            setCode(e.target.value.replaceAll(" ", ""));
          }}
        />
      </label>
      <div className="mt-5 flex w-full justify-between gap-2.5">
        <button
          type="button"
          onClick={goBackward}
          className="flex h-[36px] w-[36px] shrink-0 items-center justify-center gap-1.5 rounded-full bg-gray-100 text-sm font-medium transition hover:bg-gray-200 active:bg-gray-300"
        >
          <FaChevronLeft />
        </button>
        <button
          type="submit"
          className="flex shrink-0 items-center justify-center gap-1.5 rounded-full bg-gray-100 py-2 px-5 text-sm font-medium transition hover:bg-gray-200 active:bg-gray-300"
        >
          Log in <FaChevronRight className="text-xs" />
        </button>
      </div>
    </form>
  );
};

export default SignIn;
