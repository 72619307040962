import tiktok from "../../assets/tiktok.png";
import { TikTokEmbed } from "react-social-media-embed";

const urls = [
  "https://www.tiktok.com/@couponingwithtina/video/7243552051454594347",
  "https://www.tiktok.com/@bodykintsugi/video/7245126151302057258?q=freebieflow&t=1690416005260",
  "https://www.tiktok.com/@savingwithkitty/video/7256250956940053806?q=freebieflow&t=1690416005260",
  "https://www.tiktok.com/@eburch23/video/7248560129325436202?q=freebieflow&t=1690416005260",
];

const TikTok = () => {
  return (
    <section>
      <div className="mb-10 flex flex-col items-center gap-2.5">
        <h2 className="text-3xl font-extrabold tracking-tight">As seen on</h2>
        <img src={tiktok} alt="TikTok Logo" className="w-40" />
      </div>
      <div className="hidden grid-cols-2 gap-5 lg:grid xl:grid-cols-4">
        {urls.map((url) => (
          <div className="h-[572px] overflow-hidden">
            <TikTokEmbed
              url={url}
              className="h-[572px] w-[325px] shrink-0 overflow-hidden"
            />
          </div>
        ))}
      </div>
      <div className="grid gap-5 sm:grid-cols-2 lg:hidden">
        {urls
          .map((url) => (
            <div className="h-[572px] overflow-hidden">
              <TikTokEmbed
                url={url}
                className="h-[572px] w-[325px] shrink-0 overflow-hidden"
                width={325}
              />
            </div>
          ))
          .slice(0, 2)}
      </div>
    </section>
  );
};

export default TikTok;
