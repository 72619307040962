import axios from "axios";
import { useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useDataContext } from "../../context/UserContext";
import Subscriptions from "../settings/Subscriptions";
import UpdateInfo from "../settings/UpdateInfo";
import Wrapper from "../wrappers/Wrapper";
import { useIntercom } from "react-use-intercom";
import { plans } from "../../data/config";
import useAxios from "axios-hooks";
import AffiliateProgram from "../settings/Referral";

const Settings = () => {
  const { user } = useDataContext();
  const { update } = useIntercom();
  const [currentEmail, setCurrentEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(
    plans.find((plan) => plan.maxAccounts === user.maxAccounts)
  );
  const [annualBillingEnabled, setAnnualBillingEnabled] = useState(true);

  const [{ data: checkoutList = [] }] = useAxios("/analytics/checkouts");

  let subscriptionName;
  try {
    subscriptionName = plans.find(
      (plan) => plan.priceId === user.subscription.price_id
    ).name;
  } catch (error) {
    subscriptionName = "None";
  }

  update({
    email: user.username,
    userId: user._id.toString(),
    customAttributes: {
      "First Name": user.firstName,
      "Last Name": user.lastName,
      "Created At": user.createdAt.toString(),
      Subscription: subscriptionName,
      "Is Cancelling": user.subscription.deleted,
      "Last Checkout": checkoutList[0]?.time.toString() || "None",
      Deals: checkoutList.filter((checkout) => checkout.newPrice !== 0).length,
      Freebies: checkoutList.filter((checkout) => checkout.newPrice === 0)
        .length,
      "Total Checkouts": checkoutList.length,
    },
  });

  return (
    <Wrapper>
      <div>
        <div className="mb-4 flex items-center gap-1.5 font-semibold tracking-tight opacity-60">
          <Link
            to="/dashboard"
            className="flex items-center gap-1.5 leading-none"
          >
            <FaChevronLeft className="text-sm" />
            Back to dashboard
          </Link>
        </div>
        <UpdateInfo
          currentEmail={currentEmail}
          setCurrentEmail={setCurrentEmail}
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
        />
        {user.subscription?.price ? <AffiliateProgram /> : null}
        {user.maxAccounts > 0 && (
          <Subscriptions
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
            annualBillingEnabled={annualBillingEnabled}
            setAnnualBillingEnabled={setAnnualBillingEnabled}
            plans={plans}
          />
        )}
        <div className="mt-10 flex w-full justify-end">
          <button
            onClick={() => {
              axios("/logout", { method: "POST" }).then(() => {
                window.location.href = "/";
              });
            }}
            className="ml-auto items-center justify-end gap-1.5 rounded-md bg-gray-200 px-4 py-3 font-medium leading-none tracking-wide transition-all hover:bg-gray-300 active:bg-gray-400 lg:inline-flex lg:text-sm"
          >
            Log out
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

export default Settings;
