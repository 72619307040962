import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { Cancel, Pause, Bot, Default } from "../settings/Cancel";

export const ModalState = {
  DEFAULT: "DEFAULT",
  BOT: "BOT",
  AFFORD: "AFFORD",
  ITEMS: "ITEMS",
};

const ConfirmCancelPage = ({ cancelOpen, setGonnaCancel }) => {
  const [modalState, setModalState] = useState(ModalState.DEFAULT);

  useEffect(() => {
    if (cancelOpen) {
      setModalState(ModalState.DEFAULT);
    }
  }, [cancelOpen]);

  const ModalBody = () => {
    switch (modalState) {
      case ModalState.DEFAULT:
        return <Default setModalState={setModalState} />;
      case ModalState.BOT:
        return <Bot setGonnaCancel={setGonnaCancel} />;
      case ModalState.AFFORD:
        return <Pause setGonnaCancel={setGonnaCancel} />;
      case ModalState.ITEMS:
        return <Cancel setGonnaCancel={setGonnaCancel} />;
      default:
        return <Default setModalState={setModalState} />;
    }
  };

  return (
    <Transition.Root show={Boolean(cancelOpen)} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-20"
        onClose={() => setGonnaCancel(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur transition-opacity" />
        </Transition.Child>
        <form className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative flex w-full transform flex-col items-center justify-between overflow-hidden rounded-md bg-white p-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-sm sm:p-6">
                <div className="mx-auto text-xl font-semibold tracking-tight">
                  Cancel Subscription
                </div>

                <ModalBody />

                <div className="mt-4">
                  <button
                    type="button"
                    onClick={() => setGonnaCancel(false)}
                    className="button-gradient button-gradient flex w-full items-center justify-center gap-1.5 rounded-md px-4 py-3 font-medium leading-none text-button-text transition-all lg:text-sm"
                  >
                    Never mind
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </form>
      </Dialog>
    </Transition.Root>
  );
};

export default ConfirmCancelPage;
