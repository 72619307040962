import { FaCheckCircle, FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const steps = [
  {
    icon: <FaCheckCircle />,
    number: 1,
    title: "Zero Hassles",
    description:
      "Feel free to cancel or pause your subscription at any time, with no questions asked. Just click the Cancel button on our dashboard!",
    color: "bg-gradient-to-br from-[#FA695F] to-[#C445AA]",
  },
  {
    icon: <FaCheckCircle />,
    number: 2,
    title: "Ultimate Savings",
    description:
      "With our freebie selection, you'll be getting tons of fun items every month. Want to save even more? You can also configure Freebie Flow to check out highly discounted items",
    color: "bg-gradient-to-br from-[#C445AA] to-[#7062DD]",
  },
  {
    icon: <FaCheckCircle />,
    number: 3,
    title: "Exclusive Access",
    description:
      "Get access to the FreebieFlow community and enjoy exclusive access to high-quality free products, handpicked by our AI.",
    color: "bg-gradient-to-br from-purple-500 to-blue-500",
  },
];

const HowItWorks1 = () => {
  return (
    <section className="py-20 px-5 lg:px-10">
      <div className="container flex flex-col items-center">
        <h2 data-aos="fade-up" className="mb-20 font-medium">
          Why use <span className="text-gradient mb-5">FreebieFlow?</span>
        </h2>

        <div data-aos="fade-up" className="grid gap-10 lg:grid-cols-3">
          {steps.map((step, index) => {
            return (
              <div
                key={index}
                className="relative z-10 flex flex-col items-center rounded-lg bg-white p-10 shadow-md"
              >
                <div className="relative">
                  <div className="bg-gradient mb-5 inline-block rounded-full p-1 text-5xl leading-none text-white shadow-md">
                    {step.icon}
                  </div>
                </div>
                <h3 className="text-gradient mb-2.5 text-center text-3xl">
                  {step.title}
                </h3>
                <p className="text-center"> {step.description}</p>
              </div>
            );
          })}
        </div>

        <Link
          to="/dashboard/register"
          className="button-gradient mt-16 !rounded-full"
        >
          Start getting freebies now! <FaChevronRight className="text-xs" />
        </Link>
      </div>
    </section>
  );
};

export default HowItWorks1;
