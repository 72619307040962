import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Link } from "react-router-dom";
import { useDataContext } from "../../context/UserContext";

dayjs.extend(relativeTime);

// To use this component: <UglyBanner />

const UglyBanner = () => {
  const { user } = useDataContext();
  const subscriptionActive = new Date(user?.subscription?.end) > new Date();

  if (user && user?.subscription && subscriptionActive) {
    const cancelDate = dayjs(user?.subscription?.end).fromNow();
    const cancelMessage = `Your subscription is set to cancel in ${cancelDate.replaceAll(
      " ago",
      ""
    )}.`;
    const uncancelLink = (
      <Link to="/dashboard/settings" className="text-black underline">
        Uncancel?
      </Link>
    );

    if (user?.subscription?.deleted === false) return null;
    return (
      <div className="relative top-0 z-[9999999999] flex w-full items-center justify-center bg-gray-100 px-2.5 text-lg text-gray-500 shadow-lg">
        <p className="text-center lg:block">
          {cancelMessage} {uncancelLink}
        </p>
      </div>
    );
  }
};

export default UglyBanner;
