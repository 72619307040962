import axios from "axios";
import toast from "react-hot-toast";

export const updatePlan = (priceId, onUpdate) => {
  return axios("/change_plans", {
    method: "POST",
    data: {
      priceId: priceId,
    },
  })
    .then((data, ...args) => {
      toast.success(data.data.status);
      onUpdate(...args);
      setTimeout(() => {
        window.location.reload(true);
      }, 2500);
    })
    .catch((e) => {
      const error = e?.response?.data?.error;
      toast.error(error ?? "Something went wrong!");
    });
};

export const previewPlanChange = (priceId, onUpdate) => {
  return axios("/preview_plan_change", {
    method: "POST",
    data: {
      priceId: priceId,
    },
  })
    .then((res) => {
      onUpdate(res);
    })
    .catch((e) => {
      const error = e?.response?.data?.error;
      toast.error(error ?? "Something went wrong!");
    });
};

export const checkout = (priceId) => {
  return axios("/pay", {
    method: "POST",
    data: {
      priceId: priceId,
      successUrl: window.location.origin + "/payment/success",
      cancelUrl: window.location.origin + "/dashboard/plans",
    },
  })
    .then(({ data }) => {
      window.location.replace(data.url || "/");
    })
    .catch((e) => {
      const error = e?.response?.data?.error;
      toast.error(error ?? "Something went wrong!");
    });
};

export const redirectToPortal = () => {
  return axios("/get_portal", {
    method: "GET",
  })
    .then(({ data }) => {
      window.location.href = data.url;
    })
    .catch((e) => {
      const error = e?.response?.data?.error;
      toast.error(error ?? "Something went wrong!");
    });
};

export const is2FASecret = (secretKey) => {
  const regex = /^[A-Z2-7]{32,64}$/;
  return regex.test(secretKey);
};
