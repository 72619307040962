import axios from "axios";
import toast from "react-hot-toast";

export const displayChurnkeyWindow = (user) => {
    return axios("/churnkey", {
        method: "GET",
    })
        .then(({ data }) => {
            window.churnkey.init('show', {
                subscriptionId: user.subscription.id, // recommended unless Paddle
                customerId: user.subscription.customer, // required unless Paddle
                authHash: data.hash, // required
                appId: '2w7hbd3pr', // required
                mode: 'live', // set to 'test' to hit test billing provider environment
                provider: 'stripe', // set to 'stripe', 'chargebee', 'braintree', 'paddle'
                record: true, // set to false to skip session playback recording
            })
        })
        .catch((e) => {
            const error = e?.response?.data?.error;
            toast.error(error ?? "Something went wrong!");
        });
};