import clsx from "clsx";
import { useState } from "react";
import {
  FaCircle,
  FaLock,
  FaPause,
  FaPencilAlt,
  FaPlay,
  FaTrash,
} from "react-icons/fa";

const AccountCard = ({
  index,
  togBot,
  setActiveAccount,
  setEditAccountModal,
  setDeleteAccountModal,
  account,
  AccountStatus,
}) => {
  const [moreInfoExpanded] = useState(true);

  return (
    <div
      key={account.email + index}
      className="w-full p-5 mx-auto bg-white border rounded-md shadow-sm"
    >
      <div className="flex justify-between">
        <div className="inline-flex w-full items-center gap-1.5 truncate text-sm">
          <FaCircle
            className={clsx(
              "tooltip shrink-0 text-sm",
              account.metadata?.cookie && !account.metadata?.error
                ? account.active
                  ? "text-emerald-500"
                  : "text-rose-400"
                : "text-yellow-400"
            )}
          />
          {account.email}
        </div>
      </div>

      {moreInfoExpanded ? (
        <div className="grid gap-1 mt-1 text-xs text-left text-gray-500">
          <div className="mt-2.5 flex w-full gap-1.5">
            {account.metadata?.error || !account.metadata?.cookie ? (
              <button
                disabled
                className="flex w-full items-center justify-center gap-1.5 rounded-full border px-3.5 py-2.5 text-xs text-gray-500 transition hover:text-gray-800 lg:py-1.5"
              >
                <FaLock className="text-xs" />
                Waiting
              </button>
            ) : account.active ? (
              <button
                onClick={() => {
                  setActiveAccount(account);
                  togBot(account);
                }}
                className="flex w-full items-center justify-center gap-1.5 rounded-full border px-3.5 py-2.5 text-xs text-gray-500 transition hover:text-gray-800 lg:py-1.5"
              >
                <FaPause className="text-xs" />
                Pause
              </button>
            ) : (
              <button
                onClick={() => {
                  setActiveAccount(account);
                  togBot(account);
                }}
                className="flex w-full items-center justify-center gap-1.5 rounded-full border px-3.5 py-2.5 text-xs text-gray-500 transition hover:text-gray-800 lg:py-1.5"
              >
                <FaPlay className="text-xs" />
                Start
              </button>
            )}

            <button
              onClick={() => {
                setActiveAccount(account);
                setEditAccountModal(true);
                window.history.pushState({}, "", "/dashboard/edit");
              }}
              className="flex w-full items-center justify-center gap-1.5 rounded-full border px-3.5 py-2.5 text-xs text-gray-500 transition hover:text-gray-800 lg:py-1.5"
            >
              <FaPencilAlt /> Edit
            </button>

            <button
              onClick={() => {
                setActiveAccount(account);
                setDeleteAccountModal(true);
                window.history.pushState({}, "", "/dashboard/delete");
              }}
              className="flex w-full items-center justify-center gap-1.5 rounded-full border px-3.5 py-2.5 text-xs text-gray-500 transition hover:text-gray-800 lg:py-1.5"
            >
              <FaTrash /> Delete
            </button>
          </div>

          <div
            title={<AccountStatus account={account} />}
            className="mt-2.5 rounded-md border p-2.5 text-gray-600 shadow-sm"
          >
            <AccountStatus account={account} />{" "}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AccountCard;
