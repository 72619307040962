import { useEffect, useState } from "react";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import Footer from "../landing/sections/Footer";
import { FaSearch } from "react-icons/fa";
import CategoryList from "./components/CategoryList";
import ProductList from "./components/ProductList";
import {
  useFilteredProducts,
  useVisibleProducts,
  useInfiniteScroll,
} from "./useCustomHooks";
import { useDataContext } from "../context/UserContext";

const Warehouse = () => {
  const [data, setData] = useState({ items: {} });
  const [selectedCategory, setSelectedCategory] = useState("");
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isFailed, setIsFailed] = useState(false);

  const products = useFilteredProducts(data, selectedCategory, search, filter);
  const { visibleProducts, loadMoreProducts } = useVisibleProducts(products);
  const { user } = useDataContext();
  const navigate = useNavigate();

  const handleSearchChange = debounce((value) => {
    setSearch(value.toLowerCase());
  }, 300);

  useInfiniteScroll(() => {
    if (
      window.innerHeight + window.pageYOffset >=
      document.body.offsetHeight - 200
    ) {
      setTimeout(() => {
        loadMoreProducts();
      }, 1000);
    }
  });

  const categories = [
    "All",
    ...Array.from(
      new Set(Object.values(data.items).map((item) => item.category))
    ),
  ];

  useEffect(() => {
    let connected = false;
    let socket = null;

    const connectWebSocket = () => {
      socket = new WebSocket("wss://warehouse.freebieflow.com/ws");

      socket.addEventListener("open", () => {
        setIsFailed(false);
        connected = true;
        socket.send(JSON.stringify({ action: "get-items" }));
      });

      socket.addEventListener("message", (event) => {
        const { action, items, ...info } = JSON.parse(event.data);

        if (action) {
          switch (action) {
            case "add":
              setData((prevData) => ({
                items: { ...prevData.items, [info.asin]: info },
              }));
              break;
            case "remove":
              setData((prevData) => {
                const { [info.asin]: removedItem, ...remainingItems } =
                  prevData.items;
                return { items: remainingItems };
              });
              break;
            default:
              console.log(`Unknown action: ${action}`);
              break;
          }
        }

        if (items) {
          setData({ items });
        }
      });

      socket.addEventListener("error", () => {
        connected = false;
        setIsFailed(true);
        setTimeout(connectWebSocket, 2500); // Retry connection after 1 second
      });

      socket.addEventListener("close", () => {
        connected = false;
        setIsFailed(true);
        setTimeout(connectWebSocket, 2500); // Retry connection after 1 second
      });
    };

    connectWebSocket();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (visibleProducts.length > 0) {
      setIsLoading(false);
    }
  }, [visibleProducts]);

  if (user.subscription && new Date(user.subscription.end) > new Date()) {
    return (
      <>
        <main className="mx-auto flex w-full bg-gray-100">
          <div className="hidden w-full border-r bg-white p-5 xl:block xl:w-1/6">
            <h1 className="mb-5 text-xl font-medium leading-none">
              Categories
            </h1>
            <CategoryList
              categories={categories}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
          </div>
          <div className="w-full p-5 xl:w-5/6">
            <div className="relative flex items-center justify-between">
              <h1 className="mb-5 text-xl font-medium leading-none opacity-0 sm:opacity-100">
                Products
              </h1>
              <div className="absolute right-0 -top-2.5 flex">
                <div className="relative flex items-center">
                  <input
                    type="text"
                    placeholder="Search..."
                    onChange={(e) => handleSearchChange(e.target.value)}
                    className="w-32 rounded border-0 py-2.5 pl-4 pr-8 text-sm shadow-md lg:w-40"
                  />
                  <FaSearch className="absolute right-2.5 bg-white text-sm text-gray-400" />
                </div>
                <select
                  name="filters"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  className="ml-2.5 w-40 rounded-md border-0 bg-white py-2.5 px-4 text-sm text-gray-500 shadow-md"
                >
                  <option value="">Sort by...</option>
                  <option value="biggestDiscount">Biggest Discount</option>
                  <option value="lowestPrice">Lowest Price</option>
                  <option value="highestPrice">Highest Price</option>
                </select>
              </div>
            </div>
            <ProductList
              isLoading={isLoading}
              isFailed={isFailed}
              products={visibleProducts}
              allProducts={products}
            />
          </div>
        </main>
        <div className="fixed inset-x-0 bottom-0 bg-white px-4 py-2.5 shadow-md xl:hidden">
          <select
            className="w-full rounded-md border-2 border-gray-400 bg-white py-2.5 px-4 text-sm text-gray-500 shadow-lg"
            onChange={(e) => {
              setSelectedCategory(e.target.value);
            }}
          >
            <option value="">Select a category...</option>
            {categories.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
        <Footer />
      </>
    );
  } else {
    navigate("/dashboard");
  }
};

export default Warehouse;
