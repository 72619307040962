import { useState, useEffect } from "react";

export const useFilteredProducts = (data, selectedCategory, search, filter) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const itemsArray = Object.values(data.items);

    const filteredProducts = itemsArray.filter((item) => {
      return (
        (search === "" || item.title.toLowerCase().includes(search)) &&
        (selectedCategory === "All" ||
          selectedCategory === "" ||
          item.category === selectedCategory)
      );
    });

    switch (filter) {
      case "biggestDiscount":
        filteredProducts.sort((a, b) => b.discount - a.discount);
        break;
      case "lowestPrice":
        filteredProducts.sort((a, b) => a.offerPrice - b.offerPrice);
        break;
      case "highestPrice":
        filteredProducts.sort((a, b) => b.offerPrice - a.offerPrice);
        break;
    }

    setProducts(filteredProducts);
  }, [data, selectedCategory, search, filter]);

  return products;
};

export const useVisibleProducts = (products) => {
  const [visibleProducts, setVisibleProducts] = useState([]);

  const loadMoreProducts = () => {
    const newVisibleProducts = products.slice(0, visibleProducts.length + 50);
    setVisibleProducts(newVisibleProducts);
  };

  useEffect(() => {
    loadMoreProducts();
  }, [products]);

  return { visibleProducts, loadMoreProducts };
};

export const useInfiniteScroll = (onScroll) => {
  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [onScroll]);
};
