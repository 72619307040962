import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import {
  getAffiliate,
  hasAffiliate,
  updatePaypal,
} from "../../utils/referrals";

const validateEmail = (email) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

const AffiliateProgram = ({}) => {
  const [email, setEmail] = useState("");
  const [affiliate, setAffiliate] = useState(null);
  const [makeAffiliate, setMakeAffiliate] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  useEffect(() => {
    hasAffiliate((data) => {
      if (data.hasAffiliate) {
        getAffiliate(null, (data) => {
          setAffiliate(data);
        });
      } else {
        setMakeAffiliate(true);
      }
    });
  }, []);

  return !affiliate ? (
    makeAffiliate ? (
      <div className="overflow-hidden rounded-md">
        <div className="gradient z-[600] h-1 w-full rounded-t-lg"></div>
        <div className="mb-5 bg-white p-5 shadow-md">
          <div className="mb-6">
            <div className="text-lg font-medium">Referral Program</div>
            <p className="text-sm">
              Enroll in our referral program by providing us with your PayPal
              email!
            </p>
          </div>
          <div className="text-lg font-medium">PayPal Email</div>
          <p className="text-sm">
            This is the PayPal email that you would like to receive payments to.
          </p>
          <input
            id="affiliate-paypal"
            type="email"
            className="mt-1 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm transition focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm md:w-1/3"
            placeholder="PayPal Email"
            value={email}
            onChange={handleEmailChange}
          />
          {email.length > 2 ? (
            <span className="text-xs font-normal text-rose-500">
              {!validateEmail(email) && "Please enter a valid email address."}
            </span>
          ) : null}
          <div>
            <button
              className="button-gradient mt-6 rounded-md px-4 py-3 font-medium text-white"
              onClick={() => {
                if (validateEmail(email)) {
                  updatePaypal(email);
                } else {
                  toast.error("Please enter a valid email address.");
                }
              }}
            >
              Enroll
            </button>
          </div>
        </div>
      </div>
    ) : (
      ""
    )
  ) : (
    <div className="overflow-hidden rounded-md">
      <div className="gradient z-[600] h-1 w-full rounded-t-lg"></div>
      <div className="mb-10 bg-white p-5 shadow-md">
        <div className="flex gap-8">
          <div>
            <div className="text-lg font-medium">Visitor Count</div>
            <p className="text-sm">
              People who have visited your referral link.
            </p>
            <div className="mt-2 text-2xl font-medium text-gray-500">
              {affiliate.visitors}
            </div>
          </div>
          <div>
            <div className="text-lg font-medium">Purchases</div>
            <p className="text-sm">
              People who have paid for a subscription using your referral link.
            </p>
            <div className="mt-2 text-2xl font-medium text-gray-500">
              {affiliate.conversions}
            </div>
          </div>
        </div>
        <div className="mb-6 mt-6">
          <div className="text-lg font-medium">Referral Program</div>
          <p className="text-sm">
            Share your personalized Referral Link and earn $10 per paying user!
          </p>
        </div>
        <div className="mt-2 flex items-center gap-2.5">
          <input
            id="affiliate-link"
            type="text"
            className="flex-1 rounded-md border border-gray-300 px-4 py-3"
            value={
              "https://freebieflow.com/?" + affiliate.links[0].url.split("?")[1]
            }
            readOnly={true}
          />
          <button
            className="button-gradient rounded-md px-4 py-3 font-medium text-white"
            onClick={() => {
              navigator.clipboard.writeText(
                "https://freebieflow.com/?" +
                  affiliate.links[0].url.split("?")[1]
              );
              toast.success("Copied to clipboard!");
            }}
          >
            Copy
          </button>
        </div>
        <div className="mt-6 flex flex-wrap justify-between">
          <div>
            <div className="text-lg font-medium">PayPal Email</div>
            <p className="text-sm">
              This is the PayPal email that you would like to receive payments
              to.
            </p>
            <input
              id="affiliate-paypal"
              type="email"
              className="mt-6 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm transition focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
              placeholder={affiliate.paypal_email || "PayPal Email"}
              onChange={handleEmailChange}
            />
            {email.length > 2 ? (
              <span className="text-xs font-normal text-rose-500">
                {!validateEmail(email) && "Please enter a valid email address."}
              </span>
            ) : null}
            <div>
              <button
                className="button-gradient mt-6 rounded-md px-4 py-3 font-medium text-white"
                onClick={() => {
                  if (validateEmail(email)) {
                    updatePaypal(email);
                  } else {
                    toast.error("Please enter a valid email address.");
                  }
                }}
              >
                Update
              </button>
            </div>
          </div>
          <div>
            <div className="text-lg font-medium">Total Earnings</div>
            <p className="text-sm">
              Total amount of money you have earned from referrals.
            </p>
            <div className="mt-6 text-2xl font-medium text-gray-500">
              ${affiliate.conversions * 10}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AffiliateProgram;
