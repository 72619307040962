import success1 from "./success-images/SUCCESS-1.webp";
import success2 from "./success-images/SUCCESS-2.webp";
import success3 from "./success-images/SUCCESS-3.webp";
import success4 from "./success-images/SUCCESS-4.webp";
import success5 from "./success-images/SUCCESS-5.webp";
import success6 from "./success-images/SUCCESS-6.webp";
import success7 from "./success-images/SUCCESS-7.webp";
import success8 from "./success-images/SUCCESS-8.webp";
import success9 from "./success-images/SUCCESS-9.webp";
import success10 from "./success-images/SUCCESS-10.webp";
import success11 from "./success-images/SUCCESS-11.webp";
import success12 from "./success-images/SUCCESS-12.webp";
import success13 from "./success-images/SUCCESS-13.webp";
import success14 from "./success-images/SUCCESS-14.webp";
import success15 from "./success-images/SUCCESS-15.webp";
import success16 from "./success-images/SUCCESS-16.webp";
import success17 from "./success-images/SUCCESS-17.webp";

const successImages = [
    success1,
    success2,
    success3,
    success4,
    success5,
    success6,
    success7,
    success8,
    success9,
    success10,
    success11,
    success12,
    success13,
    success14,
    success15,
    success16,
    success17,
];

export default successImages;
