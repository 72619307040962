import Footer from "../sections/Footer";
import Nav from "./Nav";

const Post = ({ title, body }) => {
  return (
    <div>
      <Nav />
      <header className="header mb-14 bg-gray-100">
        <div className="px-5">
          <div className="mx-auto max-w-screen-md">
            <h1 className="py-14 text-5xl font-semibold">{title}</h1>
          </div>
        </div>
      </header>
      <div className="px-5">
        <main className="mx-auto max-w-screen-md">
          <p>{body}</p>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default Post;
