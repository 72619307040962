import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ThreeDots } from "react-loading-icons";
import { useEffect } from "react";
import { useDataContext } from "../../context/UserContext";

export default function VerifyEmail() {
  const { updateUser } = useDataContext();
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axios(`/verify_email/${token}`, {
      method: "POST",
    })
      .then(() => {
        updateUser();
        navigate("/dashboard");
      })
      .catch((e) => {
        navigate("/dashboard");
      });
  }, []);

  return (
    <div className="relative bottom-20 flex h-screen items-center justify-center">
      <div className="p-5 ">
        <div className="flex flex-col items-center gap-2.5">
          <ThreeDots fill="rgba(0,0,0,0.1)" width="50px" />
          <h1 className="text-3xl font-extrabold tracking-tight">
            Loading application...
          </h1>
          <p className="text-center">
            Thanks for verifying! Please wait while we redirect you to the
            dashboard.
          </p>
        </div>
      </div>
    </div>
  );
}
