import he from "he";
import dayjs from "dayjs";
import { formatPrice } from "../../utils/analytics";
import relativeTime from "dayjs/plugin/relativeTime";
import useAxios from "axios-hooks";

dayjs.extend(relativeTime);

function CheckoutHistory() {
  const [{ data: checkoutList = [] }] = useAxios("/analytics/checkouts");

  return checkoutList.length > 0 ? (
    <div className="flex w-full shrink-0 flex-col">
      <div className="mb-2.5 font-semibold">Your recent hits</div>
      <div className="flex flex-col gap-2.5">
        {checkoutList
          ?.sort((a, b) => b.time - a.time)
          .map((checkout, i) => (
            <div
              key={i}
              className="highlight flex flex-row gap-5 rounded-md border bg-white p-5 shadow-sm hover:cursor-pointer"
              onClick={() => {
                window.open(`https://www.amazon.com/dp/${checkout.asin}`);
              }}
            >
              <img
                src={checkout.image}
                alt=""
                className="h-16 w-16 rounded-md"
              />
              <div>
                <div>{he.decode(checkout.title)}</div>
                <div className="text-xs text-gray-500">{checkout.email}</div>
                <div className="text-xs text-gray-500">
                  {dayjs(checkout.time, "YYYY-MM-DDTHH:mm:ssZ").fromNow(true)}{" "}
                  ago
                </div>
                <div className="flex gap-1.5">
                  <div className="text-xs text-gray-500 line-through">
                    ${checkout.price}
                  </div>
                  <div>
                    <div className="text-xs font-bold text-emerald-500">
                      ${formatPrice(checkout.newPrice)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  ) : (
    <div>
      <div className="mb-2.5 font-semibold">Your recent hits</div>
      <div className="mt-2.5 flex items-center justify-center rounded-md border bg-white p-5 text-center text-sm text-gray-400 shadow-sm">
        No hits yet. Check back later!
      </div>
    </div>
  );
}

export default CheckoutHistory;
