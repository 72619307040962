export const plans = [
  {
    name: "Basic",
    priceMonthly: 49,
    priceYearly: 599,
    priceId: "price_1NELSpLVMiFmGBXg7xkIg6y9",
    limit: "1 account",
    maxAccounts: 1,
    dealAccounts: 0,
    hasWarehouse: false,
    allPriceIds: [
      "price_1MimPSLVMiFmGBXgX1Ur7aRT",
      "price_1MwEebLVMiFmGBXgfi7d4Wjj",
      "price_1NDv2jLVMiFmGBXgtqxA1dhz",
      "price_1NDv2jLVMiFmGBXgtqxA1dhz",
      "price_1NELSpLVMiFmGBXg7xkIg6y9",
    ],
  },
  {
    name: "Pro",
    priceMonthly: 119,
    priceYearly: 1439,
    priceId: "price_1NELTgLVMiFmGBXg4Oo6BLqs",
    limit: "Up to 5 accounts",
    maxAccounts: 3,
    dealAccounts: 0,
    hasWarehouse: true,
    allPriceIds: [
      "price_1MimP7LVMiFmGBXgy3xi9HbO",
      "price_1MwEcELVMiFmGBXgpbBv98DH",
      "price_1NELTgLVMiFmGBXg4Oo6BLqs",
    ],
  },
  {
    name: "Family",
    priceMonthly: 209,
    priceYearly: 2508,
    priceId: "price_1NELUSLVMiFmGBXgPdRPbzPZ",
    limit: "Unlimited accounts",
    maxAccounts: 6,
    dealAccounts: 0,
    hasWarehouse: true,
    allPriceIds: [
      "price_1MimOdLVMiFmGBXgxJ8FBb1z",
      "price_1MwEdmLVMiFmGBXgopKtqIw3",
      "price_1NELUSLVMiFmGBXgPdRPbzPZ",
    ],
  },
];

export const customPlans = [
  {
    name: "UCG Creator",
    priceMonthly: 49,
    priceYearly: 588,
    priceId: "price_1NVGLuLVMiFmGBXgfBsJdUCH",
    limit: "1 account",
    maxAccounts: 1,
    dealAccounts: 0,
    hasWarehouse: false,
    allPriceIds: ["price_1NVGLuLVMiFmGBXgfBsJdUCH"],
  },
];
