import { createContext, useContext } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";

const DataContext = createContext(null);

export const DataProvider = ({ children }) => {
  const [user, updateUserState] = useState(null);
  const location = useLocation();

  async function updateUser() {
    if (Cookies.get("connect.sid") === undefined) {
      updateUserState(undefined);
      return;
    }
    try {
      const response = await axios.get("/user");
      if (response.data) {
        updateUserState(response.data);
        return;
      }
    } catch (e) {
      Cookies.remove("connect.sid");
      updateUserState(undefined);
      return;
    }
  }

  useEffect(() => {
    updateUser();
  }, []);

  if (user !== null) {
    if (
      user &&
      !location.pathname.startsWith("/terms-of-service") &&
      !location.pathname.startsWith("/privacy-policy") &&
      !location.pathname.startsWith("/dashboard") &&
      !location.pathname.startsWith("/home") &&
      !location.pathname.startsWith("/verify_email") &&
      !location.pathname.startsWith("/payment/success")
    ) {
      return <Navigate to="/dashboard" />;
    }
    return (
      <DataContext.Provider value={{ user, updateUser }}>
        {children}
      </DataContext.Provider>
    );
  }
};

export const useDataContext = () => {
  const data = useContext(DataContext);
  if (!data) {
    throw new Error("You must wrap the app in a provider.");
  }
  return data;
};
