import axios from "axios";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaChevronRight, FaEye, FaEyeSlash } from "react-icons/fa";

const ManualSetup = ({
  index,
  setManualSetup,
  email,
  setEmail,
  password,
  setPassword,
  code,
  setCode,
  codeValid,
  setCodeValid,
  setCurrentStep,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  useEffect(() => {
    if (code.length > 0)
      if (/^[A-Z2-7]{32,64}$/.test(code)) {
        setCodeValid(true);
      } else {
        setCodeValid(false);
        toast.error("Invalid 2FA Code");
      }
  }, [code]);

  const sendToServer = async () => {
    axios("/accounts", {
      method: "PUT",
      data: {
        email: email,
        password: password,
        twoFactorKey: code,
        isDealAccount: false,
        controls: {
          discount: 100,
          minPrice: 0,
          maxPrice: 1,
        },
      },
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Account added!");
          setCurrentStep(6);
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 409) {
            toast.error("Account already exists!");
          } else {
            toast.error("Something went wrong");
          }
        }
      });
  };

  return (
    <div className="!text-neutral-800 prose w-full max-w-2xl overflow-hidden rounded-lg bg-white p-5 shadow-md lg:rounded-3xl lg:p-10">
      <motion.div
        key={index}
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -100 }}
        transition={{ type: "tween" }}
        className="prose"
      >
        <h2>Manual Configuration</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (email && password && code && codeValid === true) {
              sendToServer();
            } else if (!email) {
              toast.error("Please enter an email.");
            } else if (!password) {
              toast.error("Please enter a password.");
            } else if (!code) {
              toast.error("Please enter a 2FA secret.");
            } else if (codeValid === false) {
              toast.error("Please enter a valid 2FA secret.");
            }
          }}
          className="prose grid gap-7"
        >
          <label>
            <span className="relative bottom-1.5 text-sm font-semibold">
              Amazon Account Email/Number
            </span>
            <input
              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm !ring-0 transition focus:border-highlight focus:outline-none sm:text-sm"
              placeholder="Email/Number"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <div className="flex items-center ">
            <label className="relative flex w-full flex-col justify-center">
              <span className="relative bottom-1.5 text-sm font-semibold">
                Amazon Account Password
              </span>
              <div className="flex items-center">
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm !ring-0 transition focus:border-highlight focus:outline-none sm:text-sm"
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  className={"absolute right-3.5"}
                  onClick={toggleShowPassword}
                >
                  {showPassword ? (
                    <FaEyeSlash className="text-gray-400" />
                  ) : (
                    <FaEye className="text-gray-400" />
                  )}
                </button>
              </div>
            </label>
          </div>
          <label>
            <span className="relative bottom-1.5 text-sm font-semibold">
              2FA Secret
            </span>
            <input
              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm !ring-0 transition focus:border-highlight focus:outline-none sm:text-sm"
              placeholder="2FA Secret"
              type="text"
              value={code}
              onChange={(e) => {
                setCode(e.target.value.replaceAll(" ", ""));
              }}
            />
          </label>

          <div className="mt-5 flex flex-row-reverse items-center justify-between gap-2.5">
            <button
              type="submit"
              className="flex items-center gap-1.5 rounded-full bg-gray-100 px-5 py-2 text-sm font-medium transition hover:bg-gray-200 active:bg-gray-300"
            >
              Submit <FaChevronRight className="text-xs" />
            </button>
            <button
              onClick={() => {
                setManualSetup(false);
              }}
              className="flex items-center gap-1.5 rounded-full bg-gray-100 px-5 py-2 text-sm font-medium transition hover:bg-gray-200 active:bg-gray-300"
            >
              Switch to Guided Configuration
            </button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default ManualSetup;
