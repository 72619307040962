import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import * as Sentry from "@sentry/react";

import App from "./App";
import { DataProvider } from "./context/UserContext";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import axios from "axios";

Sentry.init({
  dsn: "https://d2da5fd6dce34e21867ca570b3856f0c@o4505338166902784.ingest.sentry.io/4505338829799424",
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/api.freebieflow\.com/],
    }),
    new Sentry.Replay({
      maskAllText: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

axios.defaults.baseURL =
  process.env.REACT_APP_API_ENDPOINT || "http://localhost:8080";
axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <CookiesProvider>
      <DataProvider>
        <App />
      </DataProvider>
    </CookiesProvider>
  </BrowserRouter>
);
