import axios from "axios";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import { useState, useEffect } from "react";
import reviewData from "../../context/reviewData";
import {
  FaChevronLeft,
  FaChevronRight,
  FaEye,
  FaEyeSlash,
  FaInfoCircle,
  FaStar,
} from "react-icons/fa";

export default function Register() {
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [valid, setValid] = useState(false);
  const [slideNum, setSlideNum] = useState(0);
  const [confirmPassword, setConfirmPassword] = useState("");

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validatePassword = (password) => {
    const re = /^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return re.test(password);
  };

  const validateConfirmPassword = (password, confirmPassword) => {
    return password === confirmPassword;
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validate = () => {
    if (
      validateEmail(email) &&
      validatePassword(password) &&
      validateConfirmPassword(password, confirmPassword)
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  };

  useEffect(() => {
    try {
      window.fathom.trackGoal("A4F1JONR", 0);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    validate();
  }, [email, password, confirmPassword]);

  const postData = async (e) => {
    e.preventDefault();
    try {
      window.fathom.trackGoal("TVQE1TFX", 0);
    } catch (e) {
      console.log(e);
    }
    if (valid) {
      axios("/register", {
        method: "POST",
        data: {
          firstName,
          lastName,
          username: email,
          password: password,
        },
      })
        .then((res) => {
          toast.success("Registered successfully, please confirm your email");
          window.location.pathname = "/dashboard";
        })
        .catch((e) => {
          const error = e?.response?.data?.error;
          toast.error(error ?? "Something went wrong!");
        });
    } else {
      if (!validateConfirmPassword(password, confirmPassword))
        toast.error("Passwords do not match.");
    }
  };

  return (
    <>
      <section className="flex flex-col lg:flex-row-reverse">
        <div className="flex w-full flex-col justify-center overflow-clip bg-background px-5 py-12 sm:px-6 lg:w-1/2 lg:justify-end lg:px-8">
          <div className="relative z-20 sm:mx-auto sm:w-full sm:max-w-md">
            <Link to="/">
              <img
                className="mx-auto h-10 w-auto"
                src={logo}
                alt="FreebieFlow"
              />
            </Link>
            <h2 className="mt-6 text-center text-3xl font-semibold tracking-tight text-gray-900">
              Register an account
            </h2>
            <p className="mb-4 text-center text-sm text-gray-700">
              Getting started is easy! Simply register an account, subscribe,
              link your Amazon Prime, and start getting free products right
              away.
            </p>
            <p className="mt-2 text-center text-sm text-gray-800">
              Or{" "}
              <Link
                to="/dashboard/signin"
                className="text-gradient font-medium"
              >
                Sign in
              </Link>
            </p>
          </div>
          <div className="relative z-20 mt-8 overflow-hidden rounded-md sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-gradient h-1 w-full rounded-t-md"></div>
            <div className="rounded-b-md border-x border-b bg-white px-4 py-8 sm:px-10">
              <form onSubmit={postData} className="space-y-6">
                <div className="flex gap-2.5">
                  <div>
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-medium text-gray-800"
                    >
                      First name
                    </label>
                    <div className="mt-1">
                      <input
                        onChange={(e) => {
                          setFirstName(e.target.value);
                        }}
                        id="firstName"
                        name="firstName"
                        type="firstName"
                        autoComplete="First Name"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm transition focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-medium text-gray-800"
                    >
                      Last name
                    </label>
                    <div className="mt-1">
                      <input
                        onChange={(e) => {
                          setLastName(e.target.value);
                        }}
                        id="lastName"
                        name="lastName"
                        type="lastName"
                        autoComplete="Last name"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm transition focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-800"
                  >
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm transition focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="flex items-center justify-between text-sm font-medium text-gray-800"
                  >
                    Password
                  </label>
                  <div>
                    <div className="relative mt-1">
                      <input
                        onChange={(e) => {
                          setPassword(e.target.value);
                          validate();
                        }}
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        autoComplete="current-password"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm transition focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                      />
                      <button
                        type="button"
                        className="absolute inset-y-0 right-0 px-3 py-1"
                        onClick={toggleShowPassword}
                      >
                        {showPassword ? (
                          <FaEyeSlash className="text-gray-400" />
                        ) : (
                          <FaEye className="text-gray-400" />
                        )}
                      </button>
                    </div>
                  </div>
                  {password.length > 2 ? (
                    <span className="text-xs font-normal text-rose-500">
                      {password.length < 8 &&
                        "Password must be at least 8 characters long."}
                      {password.length >= 8 &&
                        !validatePassword(password) &&
                        "Use at least one uppercase letter and one number."}
                    </span>
                  ) : null}
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-800"
                  >
                    Confirm password
                  </label>
                  <div className="relative mt-1">
                    <input
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                      id="confirm-password"
                      name="confirm-password"
                      type={showPassword ? "text" : "password"}
                      required
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm transition focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <div className="mt-2 mt-6 flex items-center">
                    <input
                      id="terms"
                      name="terms"
                      type="checkbox"
                      required
                      className="h-4 w-4 rounded border-gray-300 text-highlight focus:ring-highlight"
                    />
                    <label
                      htmlFor="terms"
                      className="ml-2 block text-sm text-gray-800"
                    >
                      I agree to the{" "}
                      <button
                        onClick={() => {
                          window.location.href = "/terms-of-service";
                        }}
                        className="text-gradient font-medium"
                      >
                        Terms of Service
                      </button>
                    </label>
                  </div>
                </div>
                <div>
                  <div className="mt-2 mt-6 flex items-center">
                    <input
                      id="acknowledgeusa"
                      name="acknowledgeusa"
                      type="checkbox"
                      required
                      className="h-4 w-4 rounded border-gray-300 text-highlight focus:ring-highlight"
                    />
                    <label
                      htmlFor="acknowledgeusa"
                      className="ml-2 block text-sm text-gray-800"
                    >
                      I acknowledge that I must have a USA address
                    </label>
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="button-gradient flex w-full justify-center"
                  >
                    Register
                  </button>
                </div>
              </form>
            </div>

            <div className="mt-8 rounded-md border bg-white px-4 py-8 sm:px-10">
              <h3 className="mb-8 flex items-center gap-1.5 font-semibold">
                <FaInfoCircle />
                Information
              </h3>

              <h4 className="font-medium">
                Do I need to link my Amazon account now?
              </h4>
              <p className="mt-2 text-sm text-gray-500">
                Once you register and subscribe, you will be able to easily link
                your Amazon account to your FreebieFlow account in your user
                dashboard.
              </p>

              <h4 className="mt-8 font-medium">When is payment required?</h4>
              <p className="mt-2 text-sm text-gray-500">
                After you register, you will be able to add your payment
                information and subscribe to a plan. You will be able to use
                FreebieFlow immediately after subscribing.
              </p>
            </div>
          </div>
        </div>
        <div className="left-0 w-full lg:fixed lg:h-screen lg:w-1/2">
          <div
            className={`relative h-[50vh] w-full overflow-hidden bg-cover bg-center transition-all duration-500 ease-in-out lg:h-screen`}
          >
            <div className="absolute z-10 h-full w-full bg-gradient-to-t from-transparent via-black/60 to-black lg:bg-gradient-to-b"></div>
            <img
              src={reviewData[slideNum].image}
              className="h-full w-full object-cover"
              alt="slide"
            />
            <div className="absolute bottom-[40%] z-[999] px-5 lg:px-10">
              <div className="mb-5 flex justify-center gap-1.5 text-white lg:justify-start lg:text-xl">
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
              </div>
              <h1 className="text-center text-xl font-medium text-white lg:text-left lg:text-2xl">
                {reviewData[slideNum].body}
              </h1>
              <p className="mt-2 text-center text-white lg:text-left">
                {reviewData[slideNum].name}
              </p>
            </div>
            <div className="absolute left-1/2 z-50 inline-flex w-full -translate-x-1/2 -translate-y-1/2 transform justify-between px-5 pb-40 lg:top-2/3 lg:px-10 lg:pb-0 ">
              <button
                onClick={() => {
                  if (slideNum === 0) {
                    setSlideNum(10);
                  } else {
                    setSlideNum(slideNum - 1);
                  }
                }}
                className="rounded-full bg-black/80 p-5 text-4xl text-white transition hover:bg-black/20 active:bg-black/30"
              >
                <FaChevronLeft />
              </button>
              <button
                onClick={() => {
                  if (slideNum === reviewData.length - 1) {
                    setSlideNum(0);
                  } else {
                    setSlideNum(slideNum + 1);
                  }
                }}
                className="rounded-full bg-black/80 p-5 text-4xl text-white transition hover:bg-black/20 active:bg-black/30"
              >
                <FaChevronRight />
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
