import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDataContext } from "../../context/UserContext";
import { updatePlan } from "../../utils/checkout";
import { FaCheck, FaDollarSign, FaTimes } from "react-icons/fa";

const UpdatePlanModal = ({
  newPlan,
  currentPlan,
  isUpdating,
  setIsUpdating,
}) => {
  const { updateUser } = useDataContext();

  if (newPlan) {
    return (
      <Transition.Root show={Boolean(isUpdating)} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20"
          onClose={() => setIsUpdating(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur transition-opacity" />
          </Transition.Child>
          <form className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-full transform overflow-hidden rounded-md bg-white p-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-sm sm:p-6">
                  <div className="grid gap-2.5">
                    <div className="flex w-full items-center gap-2.5 rounded-md border p-5 transition">
                      <div className="w-full">
                        <div className="mb-2.5 flex w-full items-center justify-center">
                          <h3>Update plan?</h3>
                        </div>
                        <div className="grid w-full place-items-center">
                          <div className="flex flex-col justify-start gap-1.5 text-sm text-gray-800">
                            <span className="flex items-center gap-1.5">
                              <FaDollarSign className="text-sm text-highlight" />
                              <p className="line-through">
                                {currentPlan.priceMonthly}
                              </p>{" "}
                              {newPlan.priceMonthly}/mo
                            </span>
                            <span className="flex items-center gap-1.5">
                              <FaCheck className="text-sm text-highlight" />
                              <p className="line-through">
                                {currentPlan.maxAccounts}
                              </p>{" "}
                              {newPlan.maxAccounts} Freebie Account(s)
                            </span>
                            <span className="flex items-center gap-1.5">
                              <FaCheck className="text-sm text-highlight" />
                              <p className="line-through">
                                {currentPlan.dealAccounts}
                              </p>{" "}
                              {newPlan.dealAccounts} Deal Account(s)
                            </span>
                            <span className="flex items-center gap-1.5">
                              {newPlan.hasWarehouse ? (
                                <FaCheck className="text-sm text-highlight" />
                              ) : (
                                <FaTimes className="text-sm text-highlight" />
                              )}{" "}
                              Warehouse Access
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 flex gap-4">
                    <button
                      type="button"
                      onClick={() => setIsUpdating(false)}
                      className="lg:text-smtext flex w-full items-center justify-center gap-1.5 rounded-md border px-4 py-3 font-medium leading-none text-black transition-all"
                    >
                      Nevermind
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setIsUpdating(false);
                        updatePlan(newPlan.priceId, () => {
                          updateUser();
                        });
                      }}
                      className="button-gradient button-gradient flex w-full items-center justify-center gap-1.5 rounded-md px-4 py-3 font-medium leading-none text-button-text transition-all lg:text-sm"
                    >
                      Update
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </form>
        </Dialog>
      </Transition.Root>
    );
  } else {
    return (
      <Transition.Root show={Boolean(isUpdating)} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20"
          onClose={() => setIsUpdating(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur transition-opacity" />
          </Transition.Child>
          <form className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-full transform overflow-hidden rounded-md bg-white p-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-sm sm:p-6">
                  <div className="grid gap-2.5">
                    <div className="flex w-full items-center gap-2.5 rounded-md border p-5 transition">
                      <div className="w-full">
                        <div className="mb-2.5 flex w-full items-center justify-between">
                          <div className="flex items-center gap-1.5 text-sm text-gray-800">
                            <h3>Oops, something's wrong!</h3>
                          </div>
                        </div>
                        <div className="w-full">
                          <div className="flex items-center gap-1.5 text-sm text-gray-800">
                            <p>
                              You currently have not chosen a plan. Please
                              choose a plan to update to.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 flex gap-4">
                    <button
                      type="button"
                      onClick={() => setIsUpdating(false)}
                      className="button-gradient flex w-full items-center justify-center gap-1.5 rounded-md py-3 pl-10 font-medium leading-none text-button-text transition-all lg:text-sm"
                    >
                      Go back
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </form>
        </Dialog>
      </Transition.Root>
    );
  }
};

export default UpdatePlanModal;
