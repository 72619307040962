const faqs = [
  {
    question:
      "How is FreebieFlow able to send so many packages to my house for free?",
    answer:
      "Our software scrapes the entire internet for coupon codes that stack to make the product have a final cost of zero dollars. These coupon codes often come from sellers who are discounting their product to zero in order to increase their sales ranking.",
  },
  {
    question: "Why do I need to pair the software with my Amazon account?",
    answer:
      "We pair with your Amazon account to send items that are shipped directly from Amazon to ensure fast and free shipping. If you choose to check out heavily discounted items, this also allows you to have free returns. Rest assured, your data security is our #1 priority and your data is encrypted and never shared.",
  },
  {
    question: "What kind of items does FreebieFlow send, and how often?",
    answer:
      "FreebieFlow is known for sending a diverse variety of products - keeping the unboxing experience a thrill! Some items we’ve sent in the past include drones, robot vacuums, smart watches, and much more. Although how often we send the items is random, you can expect around 10 to 30 items a month!",
  },
  {
    question: "How does payment work?",
    answer:
      "You only ever pay our monthly subscription cost. We will never have any hidden fees. No matter how many free items we send you, it will always be the same price!",
  },
  {
    question: "What if I want to cancel or get a refund?",
    answer:
      "You can cancel your subscription at any time. Once you cancel, you will not be charged for the next month and your subscription will end at the end of your billing cycle.",
  },
  {
    question: "Does FreebieFlow work in any country?",
    answer:
      "FreebieFlow requires an address in the USA in order to ship free items.",
  },
];

const FAQ = () => {
  return (
    <>
      <div id="faq" className="wrapper bd-gradient relative">
        <Blobs />
        <div className="container">
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div>
              <h2
                data-aos="fade-right"
                className="text-gradient inline-block text-base font-medium"
              >
                FAQs
              </h2>
              <p
                data-aos="fade-right"
                className="mt-2 text-3xl font-semibold tracking-tight text-gray-900"
              >
                Frequently Asked Questions
              </p>
              <p data-aos="fade-right" className="mt-5 mb-20 text-base lg:mb-0">
                Can’t find the answer you’re looking for? Reach out to our{" "}
                <span href="#" className="text-gradient font-medium">
                  customer support
                </span>{" "}
                team.
              </p>
            </div>
            <div className="mt-12 lg:col-span-2 lg:mt-0">
              <dl className="space-y-5">
                {faqs.map((faq, index) => (
                  <div
                    className="overflow-hidden rounded-md shadow"
                    key={index}
                    data-aos="fade-up"
                  >
                    <dt className="bg-gradient p-5 font-medium leading-6 text-white">
                      {faq.question}
                    </dt>
                    <dd className="bg-background bg-opacity-60 p-5 text-base text-gray-800 backdrop-blur-lg">
                      {faq.answer}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;

const Blobs = () => {
  return (
    <>
      <div className="z-back absolute top-0 left-32 w-[1000px] -rotate-90 lg:-top-52 lg:w-[1100px]">
        <svg viewBox="0 0 300 300" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="#fce7f3"
            d="M38,-30.7C49.1,-16.4,57.8,-0.6,54.8,12.5C51.8,25.6,37.1,36.1,19.2,47.8C1.3,59.4,-19.9,72.3,-36.2,67.4C-52.5,62.5,-63.9,39.8,-65.3,19.1C-66.8,-1.7,-58.2,-20.6,-45.6,-35.2C-33,-49.8,-16.5,-60,-1.5,-58.8C13.5,-57.6,26.9,-44.9,38,-30.7Z"
            transform="translate(100 100)"
          />
        </svg>
      </div>
      <div className="z-back absolute bottom-10 right-0 w-[700px] opacity-75 lg:w-[300px]">
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="#fce7f3"
            d="M43.1,-69.1C55.5,-59.1,64.9,-46.5,68.9,-32.9C72.8,-19.3,71.2,-4.7,68.9,9.8C66.7,24.2,63.7,38.5,55.7,49C47.7,59.5,34.6,66.2,21.1,68.7C7.6,71.3,-6.1,69.5,-18.3,64.8C-30.4,60,-40.8,52.2,-53.9,43.3C-66.9,34.3,-82.4,24.2,-88.6,10.1C-94.7,-4,-91.5,-22.1,-81.8,-35C-72.2,-47.8,-56.3,-55.3,-41.6,-64.3C-26.9,-73.2,-13.5,-83.6,0.9,-85.1C15.4,-86.5,30.7,-79.1,43.1,-69.1Z"
            transform="translate(100 100)"
          />
        </svg>
      </div>
    </>
  );
};
