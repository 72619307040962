import successImages from "../assets/success-images";

const reviewData = [
  {
    body: "I love FreebieFlow. Their bot is so easy to use and the support is amazing. I've been using it for a few months now and I'm so happy I found it.",
    name: "Teddy M.",
    details: "FreebieFlow Customer",
    image: successImages[0],
  },
  {
    body: "Life changing app. I've been using it for a few months now. I love FreebieFlow. Their bot is so easy to use and the support is amazing.",
    name: "Raymond",
    details: "FreebieFlow Customer",
    image: successImages[1],
  },
  {
    body: "FreebieFlow makes finding deals so much easier. I've been using it to find great discounts, and I'm never disappointed.",
    name: "Harry",
    details: "FreebieFlow Customer",
    image: successImages[2],
  },
  {
    body: "I've been using FreebieFlow for a couple of years now, and it's helped me save a lot of money over the years. Highly recommend!",
    name: "Samantha T.",
    details: "FreebieFlow Customer",
    image: successImages[3],
  },

  {
    body: "The perfect way to stay up-to-date on the latest deals on Amazon. FreebieFlow is definitely worth checking out.",
    name: "Matt G.",
    details: "FreebieFlow Customer",
    image: successImages[4],
  },

  {
    body: "I'm so glad I tried out Freebies Flow! It searches Amazon for deals and discounts that can't be found anywhere else.",
    name: "Derek S.",
    details: "FreebieFlow Customer",
    image: successImages[5],
  },

  {
    body: "It's an amazing app that helps people like me save time and money while shopping online. Loving it!",
    name: "Kayla E.",
    details: "FreebieFlow Customer",
    image: successImages[6],
  },

  {
    body: "I love using FreebieFlow to find really awesome deals from Amazon! Highly recommend!",
    name: "Sam B.",
    details: "FreebieFlow User",
    image: successImages[7],
  },

  {
    body: "This has become my go-to app when I want to get the best deals possible from Amazon - highly recommend.",
    name: "Angela Z.",
    details: "FreebieFlow User",
    image: successImages[8],
  },

  {
    body: "Best way to shop on amazon! Love the convenience of being able to find free or discounted products in seconds with this bot.",
    name: "James C",
    details: "FreebieFlow Fan",
    image: successImages[9],
  },

  {
    body: "No need to wait around for amazing Deals with Freebies Flow; they’re easy to access and make incredible savings possible!",
    name: "Shannon W.",
    details: "Frequent Freebie User",
    image: successImages[10],
  },
];

export default reviewData;
