import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDataContext } from "../../context/UserContext";
import { ThreeDots } from "react-loading-icons";
import { useInterval } from "../../utils/useInterval";
import axios from "axios";

export default function SuccessfulPayment() {
  const { updateUser } = useDataContext();
  const navigate = useNavigate();

  useEffect(() => {
    try {
      window.fathom.trackGoal("LG6BMRZD", 0);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useInterval(async () => {
    const paid = await axios.get("/user/paid");

    if (paid.data && paid.data.status) {
      updateUser();
      navigate("/dashboard");
    }
  }, 1500);

  return (
    <div className="relative bottom-20 flex h-screen items-center justify-center">
      <div className="p-5 ">
        <div className="flex flex-col items-center gap-2.5">
          <ThreeDots fill="rgba(0,0,0,0.1)" width="50px" />
          <h1 className="text-3xl font-extrabold tracking-tight">
            Processing your payment...
          </h1>
          <p className="text-center">
            Thanks for your payment! Please wait while your payment is being
            processed.
          </p>
        </div>
      </div>
    </div>
  );
}
