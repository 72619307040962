import { useEffect } from "react";
import { useState } from "react";
import { Line } from "react-chartjs-2";
import dayjs from "dayjs";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import useAxios from "axios-hooks";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend
);

function formatTime(time) {
  let hours = new Date(time).getHours();
  let minutes = new Date(time).getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

function Graph() {
  const [lineData, setLineData] = useState();
  const [activeFilter, setActiveFilter] = useState("currentWeek"); // Add this line
  const [{ data: checkoutList = [], loading, error }] = useAxios(
    "/analytics/checkouts"
  );

  const checkoutTotal = checkoutList?.reduce((acc, cur) => {
    return acc + cur.price;
  }, 0);

  useEffect(() => {
    if (loading || error || !checkoutList) return;
    setMonthly();
  }, [checkoutList, error, loading]);



  const setCurrentWeek = () => {
    let checkouts = checkoutList;
    
    setActiveFilter("currentWeek");

    checkouts = checkouts.filter((item) => {
      return dayjs(new Date(item.time)).isAfter(dayjs().subtract(1, "week"));
    });

    checkouts.sort((a, b) => a.time - b.time);

    const groupedCheckouts = checkouts.reduce((acc, item) => {
      const day = new Date(item.time).toLocaleString("en-us", {
        weekday: "short",
      });
      if (!acc[day]) {
        acc[day] = [];
      }
      acc[day].push(item);
      return acc;
    }, {});

    checkouts = Object.values(groupedCheckouts);

    const days = Object.keys(groupedCheckouts);

    const lineChartData = {
      data: {
        labels: days,
        datasets: [
          {
            fill: "start",
            label: "Money Saved",
            data: checkouts.map((item) => {
              let total = 0;
              item.map((item) => {
                total += item.price;
              });
              return total;
            }),
            borderColor: "#FA4088",
            backgroundColor: "rgba(250,64,136,0.2)",
            pointRadius: 0,
            tension: 0.1,
          },
        ],
      },
    };

    setLineData(lineChartData);
  };

  const setMonthly = () => {
    let checkouts = checkoutList;

    setActiveFilter("currentMonth");


    let currentMonthCheckouts = checkouts.filter((item) => {
      return dayjs(new Date(item.time)).isSame(dayjs(), "month");
    });
    
    let lastMonthCheckouts = checkouts.filter((item) => {
      return dayjs(new Date(item.time)).isSame(dayjs().subtract(1, "month"), "month");
    });
    
    if (currentMonthCheckouts.length > lastMonthCheckouts.length) {
      checkouts = checkouts.filter((item) => {
        return dayjs(new Date(item.time)).isSame(dayjs(), "month") || dayjs(new Date(item.time)).isAfter(dayjs().subtract(1, "year"));
      });
    } else {
      checkouts = checkouts.filter((item) => {
        return dayjs(new Date(item.time)).isAfter(dayjs().subtract(1, "year"));
      });
    }
    checkouts.sort((a, b) => a.time - b.time);

    const groupedCheckouts = checkouts.reduce((acc, item) => {
      const month = new Date(item.time).toLocaleString("en-us", {
        month: "short",
      });
      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(item);
      return acc;
    }, {});

    checkouts = Object.values(groupedCheckouts);

    const months = Object.keys(groupedCheckouts);

    const lineChartData = {
      data: {
        labels: months.reverse(),
        datasets: [
          {
            fill: "start",
            label: "Money Saved",
            data: checkouts
              .map((item) => {
                let total = 0;
                item.map((item) => {
                  total += item.price;
                });
                return total;
              })
              .reverse(),
              borderColor: "#FA4088",
              backgroundColor: "rgba(250,64,136,0.2)",
              pointRadius: 0,
              tension: 0.1,
          },
        ],
      },
    };

    setLineData(lineChartData);
  };

  if (loading)
    return (
      <div className="mt-2.5">
        <div className="flex items-center justify-center p-5 py-40 text-center text-gray-400 bg-white border rounded-md shadow-sm">
          Loading analytics...
        </div>
      </div>
    );

  if (error)
    return (
      <div className="p-5 lg:pt-0">
        <div className="flex items-center justify-center p-5 py-40 text-center text-gray-400 bg-gray-100 rounded-lg">
          Error loading your checkout analytics. Please try again later.
        </div>
      </div>
    );

  if (checkoutTotal > 0) {
    return (
      <div className="mt-2.5 w-full rounded-lg border bg-white p-5 shadow-sm">
        <div className="flex flex-col justify-between md:flex-row md:items-center">
          <div className="font-semibold">🚀 Your savings over time</div>
          <div className="mt-2.5 space-x-1.5 md:ml-2 lg:mt-0">
   
            <button
            className={`rounded-full border px-3.5 py-1.5 text-xs font-medium shadow-sm ${
              activeFilter === "currentWeek"
                ? "border-gray-800 bg-gray-800 text-white !shadow-none"
                : "bg-white"
            }`}
            onClick={() => {
              setCurrentWeek();
            }}
          >
            Past Week
          </button>

          <button
            className={`rounded-full border px-3.5 py-1.5 text-xs font-medium shadow-sm ${
              activeFilter === "currentMonth"
                ? "border-gray-800 bg-gray-800 text-white !shadow-none"
                : "bg-white"
            }`}
            onClick={() => {
              setMonthly();
            }}
          >
            Monthly
          </button>
       
          </div>
        </div>
        {lineData?.data && (
          <Line
            className="mt-2.5"
            data={lineData?.data}
            options={lineData.options}
          />
        )}
      </div>
    );
  } else {
    return (
      <div className="mt-2.5">
        <div className="flex items-center justify-center p-5 py-40 text-center text-gray-400 bg-white border rounded-md shadow-sm">
          You will see your checkout analytics here once you get your first
          checkout!
        </div>
      </div>
    );
  }
}

export default Graph;
