function Spinner({ visible }) {
  if (!visible) return null;
  return (
    <div className="col-span-full flex h-36 animate-pulse flex-row items-center justify-center gap-4">
      <div class="h-14 w-14 animate-spin rounded-full border-8 border-t-8 border-gray-200 border-t-[#EE577B] duration-700 ease-in-out"></div>
    </div>
  );
}

export default Spinner;
