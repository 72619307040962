const cards = [
  {
    title: "Link your accounts",
    body: "FreebieFlow securely connects to your Amazon Prime account.",
    icon: 1,
  },
  {
    title: "Customize",
    body: "Set preferences for our AI to abide by, including what types of discounts you like.",
    icon: 2,
  },
  {
    title: "Sit Back and Relax",
    body: "Your bot scans for deals and freebies 24/7, and claims them while you sleep!",
    icon: 3,
  },
];
const HowItWorks = () => {
  return (
    <div
      id="how-it-works"
      className="relative z-20 overflow-hidden bg-background py-20 px-5 lg:px-10"
    >
      <div className="container relative z-10">
        <div className="mb-20 lg:text-center">
          <h2 className="text-gradient inline-block text-2xl font-medium">
            How it works
          </h2>
          <p className="mt-2 text-3xl font-semibold leading-8 tracking-tight text-gray-900 sm:text-4xl">
            Start getting huge discounts and freebies in a few steps
          </p>
        </div>
        <div className="grid gap-10 md:grid-cols-2 lg:grid-cols-3">
          {cards.map((card, index) => {
            return (
              <div data-aos="fade-up" key={index} className="pt-6">
                <div className="flow-root rounded-lg bg-white px-6 pb-8 shadow">
                  <div className="-mt-6">
                    <div>
                      <span className="bg-gradient inline-flex h-12 w-12 items-center justify-center rounded-md text-xl font-bold text-white shadow-md">
                        {card.icon}
                      </span>
                    </div>
                    <h3 className="mt-5 text-2xl font-medium tracking-tight text-gray-900">
                      {card.title}
                    </h3>
                    <p className="mt-2.5 text-lg text-gray-700">{card.body}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
