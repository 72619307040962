import useAxios from "axios-hooks";
import { formatPrice } from "../../utils/analytics";

const TopCards = () => {
  const [{ data: checkoutList = [] }] = useAxios("/analytics/checkouts");

  const checkoutTotal = checkoutList?.reduce((acc, cur) => {
    return acc + cur.price;
  }, 0);

  if (checkoutTotal > 0) {
    return (
      <div className="rounded-md border bg-white p-5 shadow-sm">
        <div className="mb-2.5 flex items-center gap-1.5 font-bold">
          🙂 Nice job!
        </div>
        <p className="opacity-95">
          So far, you've saved <strong>${formatPrice(checkoutTotal)}</strong>{" "}
          and gotten{" "}
          <strong>
            {checkoutList?.length} package
            {checkoutList?.length === 1 ? "" : "s"}
          </strong>
          !
        </p>
      </div>
    );
  } else {
    return null;
  }
};

export default TopCards;
