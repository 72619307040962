import Marquee from "react-fast-marquee";
import { FaStar } from "react-icons/fa";
import { SiTrustpilot } from "react-icons/si";
import reviewData from "../../context/reviewData";

const Testimonials = () => {
  return (
    <div id="testimonials" className="wrapper relative bg-background !pb-0">
      <Blobs />
      <div className="container relative z-10">
        <div className="mb-10 flex flex-col items-center lg:text-center">
          <h2
            data-aos="fade-up"
            className="text-gradient inline-block text-lg font-medium"
          >
            Testimonials
          </h2>
          <p
            data-aos="fade-up"
            className="mt-2 text-3xl font-semibold leading-8 tracking-tight text-gray-900 sm:text-4xl"
          >
            What our customers are saying
          </p>
          <a
            href="https://www.trustpilot.com/review/freebieflow.com"
            target="_blank"
            rel="noreferrer"
            data-aos="fade-up"
            className="mx-auto mt-5 inline-flex flex-col items-center self-center p-5 pt-1 !transition !duration-150 hover:shadow-md active:shadow-sm"
          >
            <div className="flex items-baseline gap-1.5 text-2xl font-medium">
              <SiTrustpilot className="relative top-[2px] text-3xl text-[#00B67A]" />{" "}
              Trustpilot
            </div>
            <div className="mt-1 flex gap-0.5">
              <div className="bg-[#00B67A] p-1">
                <SiTrustpilot className="text-xl text-white" />
              </div>
              <div className="bg-[#00B67A] p-1">
                <SiTrustpilot className="text-xl text-white" />
              </div>
              <div className="bg-[#00B67A] p-1">
                <SiTrustpilot className="text-xl text-white" />
              </div>
              <div className="bg-[#00B67A] p-1">
                <SiTrustpilot className="text-xl text-white" />
              </div>
              <div className="bg-[#00B67A] p-1">
                <SiTrustpilot className="text-xl text-white" />
              </div>
            </div>
            <div></div>
          </a>
        </div>
        <div className="flex flex-col justify-center gap-5">
          <Marquee
            gradientColor={[255, 255, 254]}
            gradientWidth={25}
            speed={55}
            className="z-90 h-full rounded-md"
          >
            {reviewData
              .map((review, index) => {
                return (
                  <article
                    key={index}
                    className="my-3 mr-5 w-60 rounded-md border-t-4 border-[#00B67A] bg-white p-5 shadow-md lg:w-96"
                  >
                    <div className="w-full">
                      <img
                        src={review.image}
                        alt={review.name}
                        className="mb-5 aspect-video w-full shrink-0 rounded-md object-cover shadow-md"
                      />
                      <div className="mb-1.5 flex flex-col justify-between lg:flex-row lg:items-center ">
                        <h4 className="text-lg font-semibold">{review.name}</h4>
                        <div className="mb-2.5 mt-1 flex items-center justify-start gap-0.5 text-lg text-[#00B67A] lg:justify-center">
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaStar />
                        </div>
                      </div>
                      <p className="line-clamp-3">{review.body}</p>
                    </div>
                  </article>
                );
              })
              .reverse()}
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;

const Blobs = () => {
  return (
    <>
      <div className="absolute right-96 w-[300px] -rotate-90 opacity-80 lg:-top-20  lg:w-[450px]">
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="#fce7f3"
            d="M29.1,-52C35.4,-41.1,36.7,-29.3,41.9,-18.9C47.1,-8.5,56.1,0.4,59.1,11.4C62,22.4,59,35.4,51,43.8C43.1,52.2,30.3,56,17.9,58.7C5.4,61.4,-6.8,62.9,-21.3,63C-35.8,63.1,-52.7,61.7,-61.8,52.7C-70.9,43.7,-72.3,27.2,-72.4,11.7C-72.6,-3.7,-71.6,-18,-63.9,-26.9C-56.2,-35.7,-41.8,-39.2,-30.1,-47.6C-18.3,-56,-9.1,-69.2,1.1,-71C11.4,-72.7,22.7,-62.9,29.1,-52Z"
            transform="translate(100 100)"
          />
        </svg>
      </div>
    </>
  );
};
