import Post from "../components/Post";

const EmailSignup = () => {
  return (
    <Post
      title="Newsletter Signup"
      body={
        <iframe
          title="Newsletter Signup"
          height="800px"
          width="800px"
          src="https://cdn.forms-content.sg-form.com/4303b773-fde3-11ed-a9a6-eafffb193b37"
        />
      }
    />
  );
};

export default EmailSignup;
