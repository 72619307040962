import Steps from "../../steps/Steps";

const AddAccount = ({ addAccountModal, setAddAccountModal, onAdd }) => {
  if (addAccountModal)
    return (
      <Steps
        onDone={onAdd}
        setAddAccountModal={setAddAccountModal}
        close={() => setAddAccountModal(false)}
        isOpen={addAccountModal}
      />
    );
  return;
};

export default AddAccount;
